import * as React from 'react';
import { Drawer, DrawerProps } from '@bb-ui/react-library/dist/components/Drawer';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
} from '@bb-ui/react-library';
import { Close, TextBook } from '@bb-ui/icons/dist/small';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ResponseTenantData } from './ReleaseStages.types';
import { ReleaseStageUnlockAllButton } from './ReleaseStageUnlockAllButton';
import { ReleaseStageUpgradeButton } from './ReleaseStageUpgradeButton';

export interface ReleaseStageDrawerProps extends DrawerProps {
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  releaseName: string;
  releaseStageId: string;
  releaseVersion: string;
  releaseNumberOfMembers: number;
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    padding: '16px',
    backgroundColor: '#ffffff',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  tenantListContainer: {
    maxHeight: 'auto',
    overflowY: 'auto',
  },
}));

export const ReleaseStageDrawer: React.FunctionComponent<ReleaseStageDrawerProps> = ({
  isOpen,
  toggleDrawer,
  releaseName,
  releaseStageId,
  releaseVersion,
  releaseNumberOfMembers,
  ...drawerProps
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { data: tenantsFromReleaseStageData } = useRestApi<ResponseTenantData>(
    apiUrl('lct', `releaseStage/${releaseStageId}/tenants`),
  );

  const classes = useStyles();

  const handleDrawerClose = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleDrawer(false);
  };

  const listContainer = (
    <div>
      <div className={classes.closeButton}>
        <IconButton onClick={handleDrawerClose} aria-label="Close">
          <Close />
        </IconButton>
      </div>
      <div className={classes.buttonContainer}>
        <ReleaseStageUpgradeButton
          releaseStageId={releaseStageId}
          releaseStageName={releaseName}
          onSuccess={() => toggleDrawer(false)}
        />
        <ReleaseStageUnlockAllButton
          releaseStageId={releaseStageId}
          releaseStageName={releaseName}
          onSuccess={() => toggleDrawer(false)}
        />
      </div>
      <Typography variant="h4" className={classes.sectionTitle}>
        {t('releaseStages.releaseStageDrawer.releaseStageInformationTitle')}
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary={t('releaseStages.releaseStageDrawer.releaseStageNameTitle')}
            secondary={releaseName}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={t('releaseStages.releaseStageDrawer.releaseStageMembersTitle')}
            secondary={releaseNumberOfMembers.toString()}
          />
        </ListItem>
      </List>
      <Divider />
      <Typography variant="h4" className={classes.sectionTitle}>
        {t('releaseStages.releaseStageDrawer.releaseStageMembersSectionTitle')}
      </Typography>
      <div className={classes.tenantListContainer}>
        <List>
          {releaseNumberOfMembers === 0 ? (
            <ListItem>
              <TextBook />
              <ListItemText
                primary={t(
                  'releaseStages.releaseStageDrawer.releaseStageTenantWithZeroMembersMessage',
                )}
              />
            </ListItem>
          ) : (
            tenantsFromReleaseStageData?.items?.map((tenant, index) => (
              <ListItem
                key={index}
                button
                onClick={() => history.push(`/tenants/${tenant.TenantId}/`)}
              >
                <ListItemText
                  primary={t('releaseStages.releaseStageDrawer.releaseStageTenantNameTitle')}
                  secondary={`${tenant.TenantName}`}
                />
              </ListItem>
            ))
          )}
        </List>
      </div>
    </div>
  );

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
      classes={{ paper: classes.drawerPaper }}
      {...drawerProps}
    >
      {listContainer}
    </Drawer>
  );
};
