import { PageTemplate } from 'components/PageTemplate';
import useRestApi from 'hooks/useRestApi';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { PaginatedTable } from 'components/PaginatedTable';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorMessage } from 'components/ErrorMessage';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { SortableTableHeaderCell } from '@bb-ui/react-library';
import { makeStyles } from '@material-ui/core/styles';
import { Item, ResponseData } from './ReleaseStages.types';
import { AddReleaseStageButton } from './AddReleaseStageButton';
import { EditReleaseStageButton } from './EditReleaseStageButton';
import { DeleteReleaseStageButton } from './DeleteReleaseStageButton';
import { ReleaseStageDrawerButton } from './ReleaseStageDrawerButton';

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: '0',
  },
  button: {
    minWidth: 'auto',
    height: '1.5rem',
    padding: '0.2rem 0.5rem',
    fontSize: '0.75rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.3rem',
    justifyContent: 'center',
  },
}));

export const ReleaseStages: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    data: releaseStageData,
    error: releaseStageError,
    loading: releaseStageLoading,
    fetch: releaseStageFetching,
  } = useRestApi<ResponseData>(apiUrl('lct', 'releaseStage/'));

  const handleReload = () => {
    releaseStageFetching();
  };

  const releases = React.useMemo<Item[]>(() => releaseStageData?.items ?? [], [releaseStageData]);

  const search =
    (filter: string) =>
    ({ releaseStage }: Item) =>
      releaseStage.toLowerCase().includes(filter.toLowerCase());

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});

  // Sorting
  const sortedReleases = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    const orderSign = sortDirection === 'asc' ? 1 : -1;

    const getComparator = (sortColumnId: string | undefined) => {
      switch (sortColumnId) {
        case 'releaseStageName':
          return (l1: { releaseStage: string }, l2: { releaseStage: string }) =>
            orderSign * l1.releaseStage.localeCompare(l2.releaseStage);
        case 'releaseStageVersion':
          return (l1: { learnVersion: string }, l2: { learnVersion: string }) =>
            orderSign * l1.learnVersion.localeCompare(l2.learnVersion);
        case 'releaseStageLearnInstances':
          return (l1: { tenantsCount: number }, l2: { tenantsCount: number }) =>
            orderSign * (l1.tenantsCount - l2.tenantsCount);
        default:
          return null;
      }
    };

    const comparator = getComparator(sortColumnId);

    if (comparator) {
      return [...releases].sort(comparator);
    }

    return releases;
  }, [releases, sortParams]);

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`releaseStage.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  let content: React.ReactElement;

  if (releaseStageLoading) {
    content = <LoadingIndicator data-testid="fnds-release-stage-init" />;
  } else if (releaseStageError) {
    content = (
      <ErrorMessage
        title={t('releaseStage.loadError')}
        message={releaseStageError.message}
        data-testid="fnds-release-stage-no-data"
      />
    );
  } else {
    content = (
      <PaginatedTable
        onSortChanged={(sortParams) => {
          setSortParams(sortParams);
          return true;
        }}
        getSortChangedAriaMessage={getAriaSortMessage}
        searchBoxProps={{ label: t('releaseStages.searchLabel') }}
        sortedData={sortedReleases}
        search={search}
        noMatchesMessage={(searchExpression) => t('releaseStages.noMatch', { searchExpression })}
        renderHead={() => (
          <TableHead>
            <TableRow>
              <SortableTableHeaderCell
                id="release-stage-table-header-release-name"
                columnId="releaseStageName"
                tooltip={t('releaseStages.sortByReleaseStageName')}
              >
                {t('releaseStages.releaseStageName')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="release-stage-table-header-release-version"
                columnId="releaseStageVersion"
                tooltip={t('releaseStages.sortByReleaseStageVersion')}
                tableCellProps={{ align: 'center' }}
              >
                {t('releaseStages.releaseStageVersion')}
              </SortableTableHeaderCell>
              <SortableTableHeaderCell
                id="release-stage-table-header-release-learn-instances"
                columnId="releaseStageLearnInstances"
                tooltip={t('releaseStages.sortByReleaseStageLearnInstances')}
                tableCellProps={{ align: 'center' }}
              >
                {t('releaseStages.releaseStageLearnInstance')}
              </SortableTableHeaderCell>
              <Tooltip
                placement="top"
                title={t('releaseStages.toolTipForActions') || ''}
                arrow={true}
                classes={{ tooltip: classes.tooltip }}
              >
                <TableCell align="center">{t('releaseStages.releaseStageActions')}</TableCell>
              </Tooltip>
            </TableRow>
          </TableHead>
        )}
        renderRow={(release, index) => (
          <TableRow
            key={release.releaseStage}
            aria-rowindex={index + 1}
            data-testid={`release-stage-table-row-${release.releaseStage}`}
          >
            <TableCell
              aria-colindex={1}
              aria-describedby="release-stage-table-header-release_stage_name"
            >
              {release.releaseStage}
            </TableCell>
            <TableCell
              align="center"
              aria-colindex={2}
              aria-describedby="release-stage-table-header-release_stage_learn_version"
            >
              {release.learnVersion}
            </TableCell>
            <TableCell
              align="center"
              aria-colindex={3}
              aria-describedby="release-stage-table-header-release_stage_learn_instances"
            >
              {release.tenantsCount}
            </TableCell>
            <TableCell
              align="center"
              aria-colcount={4}
              aria-describedby="release-stage-table-header-release_stage_actions"
            >
              <div className={classes.buttonContainer}>
                <EditReleaseStageButton releaseStage={release} onReload={handleReload} />
                <DeleteReleaseStageButton
                  releaseStageID={release.id}
                  releaseStageName={release.releaseStage}
                  onReload={handleReload}
                />
                <ReleaseStageDrawerButton
                  releaseName={release.releaseStage}
                  releaseVersion={release.learnVersion}
                  releaseNumberOfMembers={release.tenantsCount}
                  releaseStageId={release.id}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      />
    );
  }

  return (
    <>
      <PageTemplate
        showTabs
        title={t('releaseStages.pageTitle')}
        data-testid="release-stage-page"
        headerControl={<AddReleaseStageButton onReload={handleReload} />}
      >
        {content}
      </PageTemplate>
    </>
  );
};
