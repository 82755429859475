// Mobile config landing page
import React, { useState } from 'react';
import { Typography, PrimaryButton, createStyles, makeStyles, Theme } from '@bb-ui/react-library';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PageCard } from 'components/PageCard';
import { CardContent } from '@bb-ui/react-library/dist/components/CardContent';
import { CardHeader } from '@bb-ui/react-library/dist/components/CardHeader';
import { useTenantContext } from 'contexts/TenantContext';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { MobileConfigurationDetails } from './MobileConfigurationDetails';
import { urlPattern } from './MobileConstants';
import {
  ChildMobileDetails,
  MobileConfigurationFields,
  MobileDetails,
  useTenantMobile,
  ConfigurationObject,
} from './useTenantMobile';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeaderSecondary: {
      padding: theme.spacing(0, 0, 3, 0),
      borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    cardHeaderAction: {
      marginRight: 0,
    },
    cardHeaderActionControl: {
      marginRight: theme.spacing(1.5),
    },
  }),
);

export const constructConfigurationMap = (
  data: ChildMobileDetails[],
): { configuration: Record<string, MobileConfigurationFields> } => {
  const configuration = data
    .flatMap((item) => item.configuration)
    .reduce(
      (acc, config) => {
        acc[String(config.id)] = {
          id: String(config.id),
          name: config.name,
          b2Url: config.b2Url,
          authType: config.authType,
          publicId: config.publicId,
          environment: config.environment,
          tenantRegion: config.tenantRegion,
          usernameLabel: config.usernameLabel,
        };
        return acc;
      },
      {} as Record<string, MobileConfigurationFields>,
    );

  return { configuration };
};

export const updateAndRemoveChildren = (
  childrenFromAPI: ConfigurationObject,
  deletedChildren?: ConfigurationObject,
  existingModifiedChildren?: ConfigurationObject,
  newChildren?: ConfigurationObject,
): ConfigurationObject => {
  // Create a deep copy of existingChildren to avoid modifying the original object
  const updatedChildren = {
    ...childrenFromAPI,
    configuration: { ...childrenFromAPI.configuration },
  };

  // Remove deleted children
  if (deletedChildren) {
    Object.keys(deletedChildren.configuration).forEach((id) => {
      delete updatedChildren.configuration[id];
    });
  }

  // Update existing children with values from existingModifiedChildren if existingModifiedChildren is provided
  if (existingModifiedChildren) {
    Object.keys(existingModifiedChildren.configuration).forEach((id) => {
      updatedChildren.configuration[id] = existingModifiedChildren.configuration[id];
    });
  }

  // add new children with values from newChildren if provided
  if (newChildren) {
    Object.keys(newChildren.configuration).forEach((id) => {
      updatedChildren.configuration[id] = newChildren.configuration[id];
    });
  }

  return updatedChildren;
};

export const MobileConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { tenant } = useTenantContext();
  const tenantId = tenant?.id;

  const { enqueueSnackbar } = useSnackbar();

  const {
    doPatch,
    doPatchChild,
    doPutChild,
    data: dataMobile,
    error: errorMobile,
    loading: loadingMobile,
    childData,
    childError,
    childLoading,
    isSnackbarVisible,
    setIsSnackbarVisible,
  } = useTenantMobile({
    tenantId,
  });
  const classes = useStyles();

  const [isConfigUpdated, setIsConfigUpdated] = useState(true);
  const [newChildrenInfoArray, setNewChildrenInfoArray] = useState<ChildMobileDetails[]>([]);
  const [existingChildrenInfoArray, setExistingChildrenInfoArray] = useState<ChildMobileDetails[]>(
    [],
  );
  const [deleteChildrenInfoArray, setDeleteChildrenInfoArray] = useState<ChildMobileDetails[]>([]);

  // For parent details
  const [updatedDetails, setUpdatedDetails] = useState<MobileDetails | undefined>(undefined);
  const [isModifiedFields, setIsModifiedFields] = useState(false);

  const updateParentMobileDetails = (newMobileDetails: MobileDetails, isModified: boolean) => {
    setIsModifiedFields(isModified);
    if (isModified) {
      setIsConfigUpdated(false);
      setUpdatedDetails(newMobileDetails);
    }
  };

  React.useEffect(() => {
    if (loadingMobile || childLoading) {
      // Reset the state of arrays
      setNewChildrenInfoArray([]);
      setExistingChildrenInfoArray([]);
      setDeleteChildrenInfoArray([]);
      setIsModifiedFields(false);
      setIsConfigUpdated(true);
    }
  }, [loadingMobile, childLoading]);

  // callback to get the child details which are edited and update the array to be sent to the API
  const updateMobileDetailsArray = (
    setArray: React.Dispatch<React.SetStateAction<ChildMobileDetails[]>>,
    newMobileDetails: ChildMobileDetails,
    counter: number,
    deleteChild: boolean,
  ) => {
    setArray((prevChildrenInfoArray) => {
      const updatedArray = [...prevChildrenInfoArray];
      const existingIndex = updatedArray.findIndex((item) =>
        item.configuration.some((config) => config.id === newMobileDetails.configuration[0].id),
      );

      if (existingIndex !== -1) {
        // If the element with the same identifier exists, update it
        if (deleteChild) {
          // If deleteChild is true, remove the element from the array
          updatedArray.splice(existingIndex, 1);
        } else {
          updatedArray[existingIndex] = newMobileDetails;
        }
      } else if (!deleteChild) {
        // If the element with the same identifier doesn't exist, insert it at the specified counter
        updatedArray.splice(counter, 0, newMobileDetails);
      }
      setIsConfigUpdated(false);
      return updatedArray;
    });
  };

  // delete filter
  const deleteChildrenArray = (counter: number, newMobileDetails: ChildMobileDetails) => {
    setDeleteChildrenInfoArray((prevDeleteChildrenInfoArray) => {
      const updatedArray = prevDeleteChildrenInfoArray ? [...prevDeleteChildrenInfoArray] : [];

      // Insert the newMobileDetails at the specified counter
      updatedArray.splice(counter, 0, newMobileDetails);

      setIsConfigUpdated(false);
      return updatedArray;
    });
  };

  // callback to get the child details which are edited and update the array to be sent to the API
  const updateChildMobileDetails = (
    newMobileDetails: ChildMobileDetails,
    counter: number,
    newChild: boolean,
    deleteChild: boolean,
  ) => {
    if (newChild) {
      // If the child is new, add it to the newChildrenInfoArray
      updateMobileDetailsArray(setNewChildrenInfoArray, newMobileDetails, counter, deleteChild);
    } else {
      // If the child is existing and if it is modified, add it to the existingChildrenInfoArray
      updateMobileDetailsArray(
        setExistingChildrenInfoArray,
        newMobileDetails,
        counter,
        deleteChild,
      );
      if (deleteChild) {
        deleteChildrenArray(counter, newMobileDetails);
      }
    }
  };

  const isSaveButtonDisabled = () => {
    const errorChilds = existingChildrenInfoArray
      .concat(newChildrenInfoArray)
      .filter((child) =>
        child.configuration.some(
          (config) =>
            config.name.trim().length === 0 ||
            config.name.trim().length > 1000 ||
            config.usernameLabel.trim().length > 1000 ||
            !urlPattern.test(config.b2Url),
        ),
      );

    return (
      errorChilds.length > 0 ||
      (updatedDetails && (updatedDetails.configuration.notes?.trim().length ?? 0) > 1000)
    );
  };

  let mobileContent = <LoadingIndicator data-testid="loading-indicator" />;
  const safeChildData = childData ?? { configuration: [] }; // Provide a default value for childData

  // Transform API response to ChildMobileDetails array
  const transformApiResponseToChildMobileDetails = (apiResponse: any): ChildMobileDetails[] => {
    const configurations = Object.values(apiResponse.configuration) as MobileConfigurationFields[];
    return [{ configuration: configurations }];
  };

  function save() {
    // Updated children
    if (deleteChildrenInfoArray.length > 0 || newChildrenInfoArray.length > 0) {
      const childResponse = childData ? transformApiResponseToChildMobileDetails(childData) : [];
      const childrenFromAPI = constructConfigurationMap(childResponse);

      const deletedChildren = constructConfigurationMap(deleteChildrenInfoArray);
      const newchildren = updateAndRemoveChildren(
        childrenFromAPI,
        deletedChildren,
        constructConfigurationMap(existingChildrenInfoArray),
        constructConfigurationMap(newChildrenInfoArray),
      );
      doPutChild('', newchildren);
    } else if (existingChildrenInfoArray.length > 0) {
      // Update children
      doPatchChild('', constructConfigurationMap(existingChildrenInfoArray));
    }
    // update the parent details
    if (isModifiedFields) {
      const parentConfiguration = { configuration: updatedDetails?.configuration };
      doPatch('', parentConfiguration);
    }
    if (isConfigUpdated) {
      enqueueSnackbar('Configuration details updated.', { variant: 'info' });
    } else if (isSnackbarVisible) {
      // reset snackbar state
      setIsSnackbarVisible(false);
    }
  }

  // If the data is loaded and there are no errors, render the page
  if (!loadingMobile && !childLoading && dataMobile) {
    mobileContent = (
      <PageCard data-testid="mobile-display-card">
        <>
          <CardHeader
            className={classes.cardHeaderSecondary}
            title={dataMobile.configuration.name}
            titleTypographyProps={{ component: 'h3', variant: 'h2', color: 'textPrimary' } as any}
            data-testid="school-name"
            action={
              <>
                <PrimaryButton
                  onClick={() => save()}
                  className={classes.cardHeaderActionControl}
                  data-testid="save-display-edit"
                  disabled={isSaveButtonDisabled()}
                >
                  {t('mobileConfiguration.save')}
                </PrimaryButton>
              </>
            }
          />
          <CardContent>
            <MobileConfigurationDetails
              updateMobileDetails={updateParentMobileDetails}
              updateChildDetails={updateChildMobileDetails}
              mobileprops={dataMobile}
              childData={safeChildData}
              childError={childError}
              childLoading={childLoading}
            />
          </CardContent>
        </>
      </PageCard>
    );
  } else if (!loadingMobile && !childLoading && dataMobile === undefined) {
    // If the data is loaded and it is empty and there are no errors, render the error message
    mobileContent = (
      <Typography data-testid="mobile-page-loading-error">
        Mobile Registration does not exist for this tenant.
      </Typography>
    );
  } else if (!loadingMobile && !childLoading && errorMobile) {
    // If the data is loaded and there are errors, render the error message
    mobileContent = (
      <Typography data-testid="mobile-page-loading-error">{errorMobile.message}</Typography>
    );
  }

  return <>{mobileContent}</>;
};
