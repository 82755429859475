export interface Item {
  updated: number;
  created: number;
  releaseStage: string;
  learnVersion: string;
  tenantsCount: number;
  id: string;
}

export interface ResponseData {
  items: Item[];
}

export interface ReleaseStageDialogData {
  releaseStage: string;
  learnVersion: string;
}

export interface TenantItem {
  updated: number;
  created: number;
  ReleaseStageId: string;
  TenantId: string;
  TenantName: string;
}

export interface ResponseTenantData {
  items: TenantItem[];
}

export interface JobInput {
  state: string;
}

export interface JobRequest {
  jobId: string;
  jobInput?: JobInput;
}

export enum JobIds {
  UPDATE = 'UpdateTenantState',
  UPGRADE = 'UpgradeLearnInstance',
}

export enum TenantStates {
  AVAILABLE = 'AVAILABLE',
}
